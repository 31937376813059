import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import style from './style/404.module.scss'

const NotFoundPage = () => (
  <Layout>

  	<div id = { style.Page404 } >
	  	<div className = 'wrapper'>
	  		<SEO title="404: Not found" />
	  		<h1>NOT FOUND</h1>
	  		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	  	</div>
  	</div>
  </Layout>
)

export default NotFoundPage
